// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/12311c79/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/tmp/12311c79/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicos-js": () => import("/tmp/12311c79/src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-js": () => import("/tmp/12311c79/src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/12311c79/.cache/data.json")

